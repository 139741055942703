'use strict';
(function ()
{
    angular.module("QuartoApp").factory("userService", ['$rootScope', '$http', '$q', userService]);

    function userService($rootScope, $http, $q)
    {
        class userSettingInfo
        {
            //default user setting
            ClientCode = "";
            DftRcdNo = 20;
            PageSizeRcd = JSON.stringify([20, 50, 100, 500]);
            CultureKey = 1;
            ThemeKey = 1;
            TimeZone = "Singapore Standard Time";
            langKey = 1;
            lang = "en";
        };

        let userServiceFactory = {
            userSetting: new userSettingInfo(),
            setUserSetting: function ()
            {
                let that = this;
                let defer = $q.defer();

                $http.get($rootScope.masterWebApiUrl + 'UserSetting/GetUserSetting').then(function (response)
                {
                    if (response && Array.isArray(response.data) && response.data.length > 0)
                    {
                        that.userSetting.ClientCode = response.data[0].ClientCode;
                        that.userSetting.DftRcdNo = +response.data[0].DftRcdNo;
                        that.userSetting.PageSizeRcd = JSON.stringify(response.data[0].PageSizeRcd);
                        that.userSetting.CultureKey = +response.data[0].CultureKey;
                        that.userSetting.ThemeKey = +response.data[0].ThemeKey;
                        that.userSetting.TimeZone = response.data[0].TimeZone;
                        that.userSetting.langKey = +response.data[0].LangKey;

                        if (that.userSetting.langKey == 1)
                        {
                            that.userSetting.lang = "en";
                        }
                        else if (that.userSetting.langKey == 2)
                        {
                            that.userSetting.lang = "my";
                        }
                        else if (that.userSetting.langKey == 3)
                        {
                            that.userSetting.lang = "id";
                        }

                        $rootScope.userSetting = that.userSetting;

                        defer.resolve(that.userSetting);
                    }
                    else
                    {
                        defer.reject(error);
                    }
                }, function (error)
                {
                    defer.reject(error);
                });

                return defer.promise;
            },
            clearUserSetting: function ()
            {
                let that = this;

                that.userSetting = new userSettingInfo();

                $rootScope.userSetting = that.userSetting;
            }
        };

        return userServiceFactory;
    }
})();